<template>
    <div class="app">
		<div v-if="!token" class="auth pt-4 pb-4">
			<router-link to="/register" class="button secondary">Register</router-link>
			<router-link to="/login" class="button">Login</router-link>
		</div>

		<template v-if="token">
			<header>
				<a @click.prevent="$router.go(-1)" href="" class="back"><i class="fad fa-angle-left"></i></a>

				<p class="title" v-if="!process">Cart</p>
				<p class="title" v-if="process">Process Orders</p>
			</header>

			<div class="container pt-4 pb-4" v-if="!process">
				<p v-if="items.length == 0" class="text-center mb-5">There is no item :(</p>

				<div v-if="items.length != 0">
					<div class="cart-card mb-4">

						<div v-for="item in items" :key="item.id" class="cart-item">
							<div class="detail-area">
								<div class="image">
									<img :src="item.image_url" alt="image">
								</div>

								<div class="details">
									<h6 class="title mb-2">{{ item.name }}</h6>
									<p class="detail">{{ item.detail }}</p>
								</div>
							</div>

							<div class="form-area">
								<p class="price">Rp. {{ Number(item.subtotal).toLocaleString() }}</p>

								<form>
									<p class="trash" @click="removeItem(item.id)"><i class="fal fa-trash"></i></p>

									<button type="button" @click="updateQuantity(item.id, item.quantity - 1)">-</button>
									<p>{{ item.quantity }}</p>
									<button type="button" @click="updateQuantity(item.id, item.quantity + 1)">+</button>
								</form>
							</div>
						</div>
					</div>

					<div class="address mb-4">
						<h6 class="title mb-4">Insert Address</h6>
						<!-- <textarea type="text" placeholder="Alamat Rumah Di isi dengan Lengkap RT/RW, Kelurahan, Kecamatan, Provinsi, Kode Pos" class="alamat input-field" v-model="formData.address"></textarea> -->
						<!-- <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea> -->

						<select v-model="ongkirData.province" @change="updateProvince" class="form-select mb-2" name="province" id="province" data-show-subtext="true" data-live-search="true">
							<option value="null" selected hidden>Choose Province</option>
							<option :value="province.province_id" :data-tokens="province.province" v-for="province in provinces" :key="province.province_id" :id="province.province_id">{{ province.province }}</option>
						</select>

						<select v-model="ongkirData.destination" @change="getOngkir(); updateCity()" class="form-select mb-2" name="province" id="city" data-show-subtext="true" data-live-search="true">
							<option value="null" selected hidden>Choose City</option>
							<option :value="city.city_id" :data-tokens="city.city_name" v-for="city in cities" :key="city.city_id">{{ city.city_name }}</option>
						</select>

						<div class="form-floating mb-2">
							<textarea v-model="addressData.address" class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
							<label for="floatingTextarea">Address</label>
						</div>

						<div class="form-floating mb-2">
							<input v-model="addressData.postCode" type="number" class="form-control" placeholder="Leave a comment here" id="floatingTextarea">
							<label for="floatingTextarea">Postal Number</label>
						</div>
					</div>

					<div>
						<h6 class="title mb-4">Ship</h6>

						<div class="ship-list mb-2">
							<div class="radio-group">
								<input type="radio" name="pengiriman" id="jne" value="jne" @change="getOngkir" v-model="formData.ship" hidden>
								<label for="jne"><img src="@/assets/jne.png" alt="JNE"></label>
							</div>

							<div class="radio-group">
								<input type="radio" name="pengiriman" id="tiki" value="tiki" @change="getOngkir" v-model="formData.ship" hidden>
								<label for="tiki"><img src="@/assets/tiki.png" alt="TIKI"></label>
							</div>

							<div class="radio-group">
								<input type="radio" name="pengiriman" id="pos" value="pos" @change="getOngkir" v-model="formData.ship" hidden>
								<label for="pos"><img src="@/assets/pos.png" alt="POS"></label>
							</div>
						</div>

						<div class="ship-service-list mb-2" v-if="shipServices">
							<div class="radio-group" v-for="cost in shipServices.costs" :key="cost.service">
								<input type="radio" name="service" :id="cost.service" :value="cost.description" v-model="formData.ship_service" @change="updateShippingCost(cost.cost[0].value)" hidden>

								<label :for="cost.service" class="w-100 mb-1">
									<span class="d-block">{{ cost.description }}</span>
									<span style="font-size: 12px"><b>Rp. {{ Number(cost.cost[0].value).toLocaleString() }}</b> est {{ cost.cost[0].etd }} Days</span>
								</label>
							</div>
						</div>
					</div>

					<h6 class="title mb-4 mt-4">Payment Method (Transfer To)</h6>

					<div class="payment-list mb-4">
						<div class="radio-group" style="width: 48%">
							<input type="radio" id="bri" name="payment" value="Bank BRI" v-model="formData.payment_method" hidden>
							<label for="bri" class="w-100"><img src="@/assets/bri.png" alt="Bank BRI"></label>
						</div>
						<div class="radio-group" style="width: 48%">
							<input type="radio" id="kalbar" name="payment" value="Bank Kalbar" v-model="formData.payment_method" hidden>
							<label for="kalbar" class="w-100"><img src="@/assets/bank-kalbar.jpg" alt="Bank Kalbar"></label>
						</div>
					</div>
				</div>
			</div>

			<div class="container pt-4 pb-4 process-container" v-if="process">
				<h6 class="title mb-4">Please Transfer to:</h6>

				<div class="bank" v-if="payment == 'Bank BRI'">
					<div class="image">
						<img src="@/assets/bri.png" alt="Bank BRI">
					</div>

					<div class="bank-detail">
						<h6 style="font-size: 12px">No. Rekening a/n RINYUAKNG SQUAD:</h6>
						<h5 class="title norek">0089-01-019493-53-9</h5>
					</div>
				</div>

				<div class="bank" v-if="payment == 'Bank Kalbar'">
					<div class="image">
						<img src="@/assets/bank-kalbar.jpg" alt="Bank Kalbar">
					</div>

					<div class="bank-detail">
						<h6 style="font-size: 12px">No. Rekening a/n Rinyuakng Squad:</h6>
						<h5 class="title norek">2021718656</h5>
					</div>
				</div>
				

				<form class="form-group mt-5" id="proofForm">
					<label for="paymentProof" class="btn btn-info">Upload Payment Proof</label>
					<input type="file" name="picture" id="paymentProof" hidden @change="showFile">
				</form>
			</div>
			
			<div class="floating-form">
				<div v-if="!process" class="sub">Subtotal <span class="subtotal">Rp{{ Number(formData.subtotal).toLocaleString() }}</span></div>
				<div v-if="process" class="total-pay">Total Payment <span class="subtotal">Rp{{ Number(formData.subtotal).toLocaleString() }}</span></div>
				
				<button v-if="!process" type="button" class="checkout button" @click="openModal">Checkout({{ total }})</button>
				<button v-if="process" type="button" class="checkout button" @click="createOrder">Finish Order</button>
			</div>
		</template>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	data() {
		return {
			token: localStorage.getItem('token'),
			items: '',
			total: '',
			process: false,
			created_id: '',
			payment: '',
			rajaOngkirHeader: {
				headers: {
					key: '72b3ad1198b4bcb05ce8b798e855502a'
				}
			},
			provinces: '',
			cities: '',
			ongkirData: {
				province: null,
				origin: 415,
				destination: null,
				weight: 0
			},
			addressData: {
				address: '',
				province: '',
				city: '',
				postCode: ''
			},
			shipServices: null,
			formData: {
				total: 0,
				subtotal: 0,
				address: '',
				ship: '',
				ship_service: '',
				payment_method: '',
				shipping_cost: 0
			},
		}
	},
	methods: {
		getData() {
			axios.get(`cart?token=${this.token}`)
				.then(res => {
					this.items = res.data;
					this.total = res.data.length;
					
					if (this.total == 0) {
						this.formData.total = 0;

						return;
					}

					this.formData.total = 0;
					res.data.forEach(val => {
						let total = val.total * val.quantity;

						this.formData.total += total;
					});

					this.ongkirData.weight = 0;
					res.data.forEach(val => {
						this.ongkirData.weight += val.weight;
					});

					this.formData.subtotal = this.formData.total + this.formData.shipping_cost;
				})
				.catch(err => {
				});
		},
		removeItem(id) {
			axios.delete(`cart/${id}?token=${this.token}`)
				.then(res => {
					this.getData();
				})
				.catch(err => {
					console.log(err.response.data.message);
				});
		},
		updateQuantity(id, val) {
			if (val <= 0) {
				this.removeItem(id);

				return;
			};

			axios.put(`cart/${id}?token=${this.token}`, {quantity: val})
				.then(res => {
					this.getData();
				})
				.catch(err => {
					alert(err.response.data.message);
				});
		},
		updateShippingCost(value) {
			this.formData.shipping_cost = value;

			this.formData.subtotal = this.formData.total + this.formData.shipping_cost;
		},
		createOrder() {
			const form = document.getElementById('proofForm');
			const formData = new FormData(form);
			formData.append('_method', 'PUT');

			axios.post(`order/${this.created_id}?token=${this.token}`, formData)
				.then(res => {
					this.$router.push('/order');
				})
				.catch(err => {
					alert(err.response.data.message);
				}); 
		},
		showFile() {
			const file = document.getElementById('paymentProof').files[0];
			const label = document.querySelector('#proofForm label');

			label.innerHTML = file.name;
		},
		openModal() {
			if (this.formData.ship_service == '') {
				alert('Mohon Pilih Opsi Pengiriman');
				
				return;
			}

			this.formData.address = `${this.addressData.province}, ${this.addressData.city}, ${this.addressData.address} - ${this.addressData.postCode}`;

			if (!this.formData.ship) {
				alert('Mohon mengisi opsi pengiriman dengan benar');
				
				return;
			}

			if (!this.formData.address) {
				alert('Mohon mengisi alamat dengan benar');

				return;
			}

			if (!this.formData.payment_method) {
				alert('Mohon megisi metode pembayaran dengan benar');
				
				return;
			}

			axios.post(`order?token=${this.token}`, this.formData)
				.then(res => {
					this.created_id = res.data;
					this.process = true;
					this.payment = this.formData.payment_method;
				})
				.catch(err => {
					alert(err.response.data.message);
				})
		},
		getProvince() {
			axios.get(`province`)
				.then(res => {
					this.provinces = res.data.rajaongkir.results;
				})
				.catch(err => {
					console.log(err.response.data);
				}); 
		},
		getCity() {
			axios.get(`city`)
				.then(res => {
					this.cities = res.data.rajaongkir.results;
				})
				.catch(err => {
					console.log(err.response.data);
				}); 
		},
		getOngkir() {
			if (!this.formData.ship || !this.ongkirData.destination) return;

			axios.post(`cost`, {
				origin : this.ongkirData.origin,
				destination : this.ongkirData.destination,
				weight : this.ongkirData.weight,
				courier : this.formData.ship
			})
				.then(res => {
					this.shipServices = res.data.rajaongkir.results[0];

					this.formData.ongkir = res.data.rajaongkir.results[0].costs[0].cost[0].value;
				})
				.catch(err => {
					console.log(err.response.data);
				}); 
		},
		updateProvince() {
			axios.get(`province/${this.ongkirData.province}`)
				.then(res => {
					this.addressData.province = res.data.rajaongkir.results.province;
				})
				.catch(err => {
					console.log(err.response.data);

				});
		},
		updateCity() {
			axios.get(`city/${this.ongkirData.destination}/province/${this.ongkirData.province}`)
				.then(res => {
					this.addressData.city = res.data.rajaongkir.results.city_name;
				})
				.catch(err => {
					console.log(err.response.data);
				}); 
		}
	},
	created() {
		if (this.token) this.getData();

		this.getProvince();
		this.getCity();
	}
}
</script>

<style scoped>

header {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

button:disabled {
	cursor: not-allowed;
}

textarea::-webkit-input-placeholder {
	font-size: 12px;
}

.form-group {
	display: flex;
	flex-direction: column;
}

.form-group label {
	margin-bottom: 10px;
}

.form-group select {
	border: 1px solid lightgray;
	padding: 5px 10px;
	outline: none;
}

.total-pay {
	margin-right: 10px;
	text-align: right;
	display: flex;
	flex-direction: column;
	font-size: 12px;
}

.total-pay span {
	text-align: right;
	align-self: end;
}

.floating-form {
	background-color: white;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	position: fixed;
	width: 375px;
	bottom: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
}

.sub {
	display: flex;
	flex-direction: column;
	margin-left: 1rem;
	font-size: 14px;
	line-height: 100%;
}

.total-pay {
	margin-left: 1rem;
	text-align: left;
}

.subtotal {
	margin-top: 5px;
	font-weight: bold;
	color: var(--primary2);
	font-size: 1.2rem;
	text-align: left;
	width: 100%;
}

.checkout {
	height: 100%;
	margin: 0;
	border-radius: 0;
	padding-left: 1rem;
	padding-right: 1rem;
}

.detail {
	font-size: 12px;
	color: gray;
	margin-bottom: 5px;
}

.cart-card {
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	padding: 10px;
	position: relative;
}

.cart-card .cart-item {
	margin-bottom: 2rem;
}

.cart-card .cart-item:last-child {
	margin-bottom: 0;
}

.cart-card .image {
	width: 60px;
	height: 60px;
	border-radius: 5px;
	overflow: hidden;
}

.cart-item {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.detail-area, .form-area {
	display: flex;
}

.form-area {
	justify-content: space-between;
	margin-top: 1rem;
}

.form-area form {
	height: 25px;
	display: flex;
}

.form-area .price {
	margin-right: 2rem;
}

.form-area form p {
	line-height: 25px;
	margin: 0 1rem;
}

.detail-area .details {
	padding-left: 1rem;
}

form button {
	background-color: var(--primary);
	border: none;
	width: 25px;
	height: 25px;
	border-radius: 3px;
	color: white;
	font-weight: bold;
}

.trash {
	cursor: pointer;
	font-size: 1rem;
	transition: .3s;
}

.trash:hover {
	color: red;
}

.ship-list, .payment-list {
	display: flex;
	justify-content: space-between;
}

.ship-service-list {
	display: flex;
	flex-direction: column;
}

input[type=radio]:checked + label {
	border-color: var(--primary2);
}

.ship-list label, .ship-service-list label, .payment-list label {
	width: 110px;
	height: 70px;
	padding: 10px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	transition: .3s;
}

.ship-service-list label {
	height: fit-content;
}

label img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.alamat {
	width: 100%;
	height: 100px;
}

.item, .subtotal {
	display: flex;
	justify-content: space-between;
}

.payment-group {
	display: flex;
	flex-direction: column;
}

.payment-group label {
	display: flex;
}

.payment-group .form-group {
	margin-bottom: 1.25rem;
}

.payment-group label .image {
	width: 25%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
}

.norek {
	color: var(--primary2);
	font-weight: 600;
}

.bank {
	display: flex;
}

.bank .image {
	width: 25%;
	margin-right: 1rem;
}

.bank .image img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.checkout.button {
	color: white;
}

.form-control:focus, .form-select:focus {
	border: 1px solid var(--primary);
	box-shadow: none;
}

</style>